import "./sass/style.sass";

//トップページのメニュースライド
document.addEventListener('DOMContentLoaded', function() {

	//トップページ以外は処理しない
	if(location.pathname !== '/') return;

	var headNav = document.getElementById('mt-header-navback');
	var headMenu = document.getElementById('mt-header-menu');
	//scrollだけだと読み込み時困るのでloadも追加
	window.addEventListener('load', headerMenu);
	window.addEventListener('scroll', headerMenu);
	headMenu.style.color = '#333';

	function headerMenu() {
		//現在の位置が50px以上かつ、クラスfixedが付与されていない時
		if((document.documentElement.scrollTop || document.body.scrollTop) > 50) {
			headNav.style.display = 'block';
			headNav.classList.add('mt-navback-fixed');
			headMenu.style.color = '#333';
		}
		//現在の位置が51px以下かつ、クラスfixedが付与されている時にfixedを外す
		else if((document.documentElement.scrollTop || document.body.scrollTop) < 51){
			headNav.style.display = 'none';
			headNav.classList.remove('mt-navback-fixed');
			headMenu.style.color = '#333';
		}
	};
});


//戻るボタンの表示
document.addEventListener('DOMContentLoaded', function() {
    var returnNav = document.getElementById('ev-return');
    window.addEventListener('scroll', returnBottom);
    function returnBottom() {
		//現在の位置が150px以上かつ、クラスfixedが付与されていない時
		if((document.documentElement.scrollTop || document.body.scrollTop) > 150) {
			returnNav.style.display = 'block';
		}
		//現在の位置が151px以下かつ、クラスfixedが付与されている時にfixedを外す
		else if((document.documentElement.scrollTop || document.body.scrollTop) < 151){
			returnNav.style.display = 'none';
		}
	};
});
